import { redirect } from 'react-router';
import { flashMessage } from '@gonfalon/flash-messages';
import { isRESTAPIError } from '@gonfalon/rest-api';
import { createCustomSearchParamsUpdater } from '@gonfalon/router';

import { parseProjectContextSearchParams } from '../parseProjectContextSearchParams';
import { readProjectContextFromRequest } from '../readProjectContextFromRequest';
import { serializeProjectContextSearchParams } from '../serializeProjectContextSearchParams';

import { type ProjectContextForRequest, fetchProjectContextForRequest } from './internal/fetchProjectContextForRequest';

export class ContextError extends Error {
  name = 'ContextError';
  message: string;
  stack?: string;

  constructor({ message, cause, stack }: { message: string; cause?: unknown; stack?: string }) {
    super(message);
    this.message = message;
    this.cause = cause;
    if (stack) {
      this.stack = stack || undefined;
    }
  }

  toJSON() {
    return { message: this.message, stack: this.stack };
  }
}

const projectContextParamsUpdater = createCustomSearchParamsUpdater({
  parse: parseProjectContextSearchParams,
  serialize: serializeProjectContextSearchParams,
});

/**
 * Ensures that a valid project context is present in the request.
 * If no project exists in the url, it redirects to the root URL.
 * If the project context is not found, it redirects to the project settings page with an appropriate message.
 * If the project context requires a redirect, it updates the URL and redirects accordingly.
 * A project context may require a redirect if the requested environment is not found in the project, or no environments are requested.
 *
 * @param {Request} request - The incoming request object.
 * @returns {Promise<ProjectContextForRequest>} - The project context for the request.
 * @throws {Error} - Throws an error if there is an unexpected issue fetching the project context data.
 */
export async function requireProjectContext(request: Request) {
  const projectContext = readProjectContextFromRequest(request);

  // If we don't have a project context in the URL, there's not much we can do
  if (!projectContext) {
    const url = new URL('/', window.location.origin);
    url.searchParams.set('root-redirect', 'true');
    throw redirect(url.toString());
  }

  let projectContextForRequest: ProjectContextForRequest;
  try {
    projectContextForRequest = await fetchProjectContextForRequest(projectContext);
  } catch (error) {
    if (isRESTAPIError(error)) {
      switch (error.status) {
        case 404:
          flashMessage(
            'missing-project',
            `Project with key "${projectContext.projectKey}" not found. Select another project to continue.`,
          );
          throw redirect('/settings/projects');
        case 403:
          flashMessage(
            'unauthorized-project',
            `No access to project with key "${projectContext.projectKey}". Check with your admin, or select another project to continue.`,
          );
          throw redirect('/settings/projects');
        default:
          break;
      }
    }

    if (error instanceof Error) {
      throw new ContextError({
        message: `Unexpected error fetching project context data: ${error.message}`,
        cause: error,
        stack: 'componentStack' in error && typeof error.componentStack === 'string' ? error.componentStack : undefined,
      });
    } else {
      throw new ContextError({
        message: 'Unknown error fetching project context data',
        cause: error,
      });
    }
  }

  // If the project context was modified, redirect to the new URL
  const { project, environments, context } = projectContextForRequest;
  if (context.selectedEnvironmentKey !== projectContext.selectedEnvironmentKey) {
    const nextUrl = new URL(request.url);

    const newSearchParamsInit = projectContextParamsUpdater(nextUrl.searchParams, projectContextForRequest.context);

    nextUrl.search = newSearchParamsInit.toString();

    throw redirect(nextUrl.toString());
  }
  return {
    project,
    environments,
    context,
  };
}
